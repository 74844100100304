// Code generated by Slice Machine. DO NOT EDIT.

import { defineAsyncComponent } from 'vue'
import { defineSliceZoneComponents } from '@prismicio/vue'

export const components = defineSliceZoneComponents({
  article_html: defineAsyncComponent(() => import('./ArticleHtml/index.vue')),
  article_image: defineAsyncComponent(() => import('./ArticleImage/index.vue')),
  article_image_and_text: defineAsyncComponent(() => import('./ArticleImageAndText/index.vue')),
  article_quote: defineAsyncComponent(() => import('./ArticleQuote/index.vue')),
  article_stats: defineAsyncComponent(() => import('./ArticleStats/index.vue')),
  article_text: defineAsyncComponent(() => import('./ArticleText/index.vue')),
  article_text_and_stat: defineAsyncComponent(() => import('./ArticleTextAndStat/index.vue')),
  blog_posts: defineAsyncComponent(() => import('./BlogPosts/index.vue')),
  boxed_content: defineAsyncComponent(() => import('./BoxedContent/index.vue')),
  card_to_action: defineAsyncComponent(() => import('./CardToAction/index.vue')),
  cards_columns: defineAsyncComponent(() => import('./CardsColumns/index.vue')),
  cards_grid: defineAsyncComponent(() => import('./CardsGrid/index.vue')),
  contact_form: defineAsyncComponent(() => import('./ContactForm/index.vue')),
  content_and_form: defineAsyncComponent(() => import('./ContentAndForm/index.vue')),
  content_and_media: defineAsyncComponent(() => import('./ContentAndMedia/index.vue')),
  events_grid: defineAsyncComponent(() => import('./EventsGrid/index.vue')),
  fullwidth_image_content: defineAsyncComponent(() => import('./FullwidthImageContent/index.vue')),
  image_gallery: defineAsyncComponent(() => import('./ImageGallery/index.vue')),
  job_listing: defineAsyncComponent(() => import('./JobListing/index.vue')),
  jobs: defineAsyncComponent(() => import('./Jobs/index.vue')),
  latest_posts: defineAsyncComponent(() => import('./LatestPosts/index.vue')),
  locations: defineAsyncComponent(() => import('./Locations/index.vue')),
  logo_slider: defineAsyncComponent(() => import('./LogoSlider/index.vue')),
  news_grid: defineAsyncComponent(() => import('./NewsGrid/index.vue')),
  page_html: defineAsyncComponent(() => import('./PageHtml/index.vue')),
  press_grid: defineAsyncComponent(() => import('./PressGrid/index.vue')),
  product_tabs: defineAsyncComponent(() => import('./ProductTabs/index.vue')),
  resources_grid: defineAsyncComponent(() => import('./ResourcesGrid/index.vue')),
  side_tabs: defineAsyncComponent(() => import('./SideTabs/index.vue')),
  stage: defineAsyncComponent(() => import('./Stage/index.vue')),
  stats: defineAsyncComponent(() => import('./Stats/index.vue')),
  success_stories_slider: defineAsyncComponent(() => import('./SuccessStoriesSlider/index.vue')),
  success_story_grid: defineAsyncComponent(() => import('./SuccessStoryGrid/index.vue')),
  tabs: defineAsyncComponent(() => import('./Tabs/index.vue')),
  team: defineAsyncComponent(() => import('./Team/index.vue')),
  testimonial_slider_double: defineAsyncComponent(
    () => import('./TestimonialSliderDouble/index.vue'),
  ),
  testimonial_slider_single: defineAsyncComponent(
    () => import('./TestimonialSliderSingle/index.vue'),
  ),
  text_only: defineAsyncComponent(() => import('./TextOnly/index.vue')),
  timeline: defineAsyncComponent(() => import('./Timeline/index.vue')),
  video: defineAsyncComponent(() => import('./Video/index.vue')),
})
